/* eslint-disable */

import Vue from 'vue'
import App from './App.vue'

import './assets/app.sass'

import Transitions from 'vue2-transitions'
Vue.use(Transitions)

import VueToastr from "vue-toastr";
Vue.use(VueToastr);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
